.footer {
    margin-top: 4em;
    font-family: Arial, sans-serif;
    margin-bottom: 6em;
}

.footer .media {
    width: 2em !important;
    height: 2em !important;
    margin-right: 0.5em;
}

.footer .text {
    font-size: 10px !important;
    transform: translateY(0px);
    text-align:center;
}

.footer .copyright {
    transform: translateY(9px);
    width: 0.5em;
}

.footer .social-svg {
    width: 100% !important;
}

.footer .buttons {
    text-align: right;
    margin-bottom: 2em;
}

.footer .buttons .store {
    width: 8em;
    margin-right: 0.5em;
    filter: brightness(150%);
}

.footer .logo {
    transform: translateY(-1px);
    width: 1em;
    margin-top: 0px;
}

.footer-link{
    padding-top: 1em;
    padding-bottom: 1em;
}

.large-font {
    font-size: medium;
}

.footer-link a {
    color: #000000; 
    transition: color 0.3s ease; 
}

.footer-link a:visited {
    color: #000000; /* This will make the visited link color the same as the regular link color */
}

.footer-link:hover {
    color: #2ba577; /* Replace with the color you want */
}

.large-font.footer-link {
    color: #000000
}

.large-font.footer-link:hover {
    color: #2ba577 !important;
}