.team .person {
  font-size: 10em;
  border-bottom: 2px solid black;
  width: 100%;
}

.team a {
  color: black;
}

.team .content {
  margin-left: 0em;
  /* margin-left: 5.5em; */
  text-align: center;
  padding: 0em !important;
}
.team .title {
  margin-bottom: 0.5em;
}

.team .MuiGridListTileBar-root {
  background-color: #2ba577;
  height: 3.5em;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.team .MuiGridListTileBar-title {
  color: white !important;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0.25em;
}

.team .MuiGridListTileBar-subtitle {
  color: white !important;
  font-weight: 500 !important;
  font-size: 16px;
}

.team .tile {
  width: 16em !important;
  margin: 1em;
  padding: 0px !important;
  border-radius: 25px;
  box-shadow: 0 4px 6px -2px silver;
}

.team .grid {
  margin: 0px !important;
}

.team .worker {
  object-fit: contain !important;
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.team .MuiGridListTile-tile:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1) !important ;
  transition: transform 0.5s;
}
