.tongue .flag {
    width: 2em;
    height: 2em;
    margin-right: 0.5em;
    transform: translateY(5px);
}

.tongue .language {
    transform: translateY(5px);
}

.tongue .button {
    transform: translateY(25px);
}

.appfixed .tongue .button {
    transform: translateY(6px);
}

.tongue .MuiMenuItem-root {
    padding-bottom: 1em;
}

.tongue .MuiList-root {
    padding: 0px !important
}

.tongue .buttonlang {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
}

.tongue .MuiPaper-root {
    box-shadow: 0 2px 15px 0 #efeff0;
}

