.form .label {
    font-size: 24px;
    margin-bottom: 1em;
}

.form .input {
    width: 94%;
    height: 3em;
    border: none !important;
    outline: none;
    font-size: 20px;
    border-radius: 30px !important;
    box-shadow: 0 2px 15px 0 #efeff0;
}

.form .input .input{
    padding-left: 2.5em;
    padding-right: 1em;
}

.form .message {
    width: 99%;
    height: 11.3em;
    border: none !important;
    outline: none;
    font-size: 20px;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
    border-radius: 30px !important;
    box-shadow: 0 2px 15px 0 #efeff0;
}

.form .button {
    background-color: transparent;
    width: 8em;
    height: 2em;
    font-size: 24px;
    margin-top: 0.5em;
    font-weight: 600 !important;
    border-radius: 30px;
}

.form .field {
    margin-top: 2em;
}

.form .sendIcon {
    margin-left: 0.3em;
    transform:translateY(4px)
}

.MuiAlert-filledSuccess {
    background-color: #ef7d50 !important;
    font-weight: bold !important;
}

.form .MuiAlert-message {
    margin-left: 2em;
    margin-right: 2em;
}

.form .content {
    width: 50%;
    padding: 0px !important;
    padding-right: 2em !important;
    margin-top: 0px !important;
}

.form .main {
    width: 50%;
}

.form .formContainer {
    display: flex;
}

.form .icon {
    transform: translateY(-43px) !important;
    padding-left: 0.5em;
    color: #424242;
}