.appbar .menuSideBar {
  display: none;
}

.logoSmall {
  display: none;
}

.content {
  max-width: 1090px;
  margin: auto;
  padding: 2em;
}

.appfixed .toolbar {
  max-width: 1140px !important;
  margin: auto !important;
}

.MuiTypography-h2 {
  font-size: 58px !important;
}

.value {
  margin-top: 12.4em !important;
}

@media (max-width: 1169px) {
  .content {
    max-width: 960px !important;
    margin: auto;
    padding: 1em;
  }

  .MuiTypography-h1 {
    font-size: 4.7rem !important;
  }

  .MuiTypography-h2 {
    font-size: 43px !important;
  }

  .MuiTypography-h3 {
    font-size: 2.5rem !important;
  }

  .MuiTypography-h4 {
    font-size: 1.7rem !important;
  }

  .MuiTypography-h5 {
    font-size: 1.17rem !important;
  }

  .MuiTypography-h6 {
    font-size: 1.1rem !important;
  }

  .provider .cover .title {
    margin-top: 8em;
  }

  .home .cover .title {
    margin-top: 7em;
    height: 12em;
  }

  .home .cover .slogun {
    margin-bottom: 11.5em;
  }
  .cover .buttons .store {
    width: 10em;
  }

  .goal .paragraph .MuiTypography-root {
    font-size: 14px !important;
  }

  .goal .content .pizza {
    width: 20em;
    height: 20em;
  }

  .value .MuiAvatar-root {
    width: 35px;
    height: 35px;
  }

  .value .MuiSvgIcon-root {
    width: 1.25em;
    height: 1.25em;
  }

  .menu .MuiListItem-root p {
    font-size: 55px !important;
  }

  .menu .MuiTypography-h6 {
    font-size: 55px !important;
  }

  .functionality .MuiGrid-root {
    margin-left: -2.5em;
  }

  .functionality .MuiPaper-root {
    width: 250px;
    height: 300px;
  }

  .functionality .stepImage {
    width: 250px;
    height: 300px;
  }

  .functionality .MuiGrid-item {
    padding: 10px !important;
    margin-left: 0.6em;
  }

  .functionality .header {
    margin-top: 1em;
  }

  .functionality .slogun {
    margin-top: 4em;
  }

  .form .label {
    font-size: 22px;
  }

  .form .input {
    font-size: 13px;
  }

  .form .message {
    font-size: 13px;
    height: 13em;
  }

  .form .button {
    font-size: 20px;
  }

  .footer .buttons .store {
    width: 7em !important;
  }

  .footer .media {
    width: 1.75em !important;
    height: 1.75em !important;
  }

  .team .tile {
    width: 13em !important;
    height: 263px !important;
    margin-left: 0.5em;
    margin-bottom: 1em;
  }

  .team .MuiGridListTileBar-title {
    font-size: 8px;
  }

  .team .MuiGridListTileBar-subtitle {
    font-size: 13px;
  }

  .notification {
    width: 12em;
    padding: 1em;
  }

  .notification .close {
    margin-left: 90%;
  }

  .notification .text {
    font-size: 13px;
  }

  .notification .input {
    font-size: 12px !important;
  }

  .notification .button {
    font-size: 16px;
  }

  .form .checker .MuiTypography-root {
    font-size: 11px;
  }

  .form .sendIcon {
    font-size: 20px;
  }

  .appbar .menuSideBar {
    display: block;
  }

  .appbar .app {
    display: none;
  }

  .logoSmall {
    object-fit: contain;
    display: block;
    margin-right: auto;
    width: 2em;
    height: 3em;
    padding: 1em;
  }

  .menuLogo {
    object-fit: contain;
    display: block;
    margin-right: auto;
    width: 2em;
    height: 3em;
    padding: 1em;
  }

  .drawer {
    width: 90% !important;
    margin: auto !important;
    margin-top: 0.4em !important;
  }

  .popup .button {
    padding-top: 0.3em;
    padding-bottom: 0.3em;
  }

  .form .icon {
    transform: translateY(-27px) !important;
  }

  .pop .icon {
    transform: translateY(-30px) !important;
  }

  .form .popInput .input {
    padding-left: 3em;
    width: 73%;
  }
}

@media (max-width: 959px) {
  .content {
    max-width: 680px !important;
    margin: auto !important;
    padding: 1em;
  }

  .MuiTypography-h1 {
    font-size: 3.7rem !important;
  }

  .MuiTypography-h2 {
    font-size: 34px !important;
  }

  .MuiTypography-h3 {
    font-size: 2.3rem !important;
  }

  .MuiTypography-h4 {
    font-size: 1.7rem !important;
  }

  .MuiTypography-h5 {
    font-size: 1.1rem !important;
  }

  .MuiTypography-h6 {
    font-size: 1rem !important;
  }

  .popup {
    float: none;
    margin-bottom: 2em;
  }

  .form {
    width: 100%;
  }

  .cover .coverContainer img {
    height: 24em;
    top: 10px;
  }

  .home .cover .title {
    margin-top: -0.5em;
    height: 9em;
  }

  .home .cover .slogun {
    margin-bottom: 10em;
  }

  .provider .cover .title {
    margin-top: 6.5em !important;
    height: 6em;
  }

  .provider .cover .slogun {
    margin-bottom: 8em;
  }

  .cover .buttons .store {
    width: 9em;
    margin-bottom: -1em;
  }

  .value {
    margin-top: 0em !important;
    margin-bottom: 1em;
  }

  .value .MuiListItemAvatar-root {
    min-width: 40px;
  }
  .value .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }

  .value .MuiSvgIcon-root {
    width: 1em;
    height: 1em;
  }

  .goal .pizza {
    margin-left: 0em;
  }

  .player {
    width: 100% !important;
    height: 22em !important;
    margin-bottom: 4em !important;
  }

  .react-player__preview {
    height: 100% !important;
  }

  .footer .buttons {
    text-align: center;
    margin-top: 1em;
  }

  .footer .icons {
    text-align: center;
  }

  .menu .MuiListItem-root p {
    font-size: 40px !important;
  }

  .menu .MuiTypography-h6 {
    font-size: 40px !important;
  }

  .menu .flag {
    width: 4em;
    height: 4em;
    transform: translateY(4.5px);
  }

  .awards .cl {
    width: 100%;
  }

  .team .tile {
    width: 11.5em !important;
    height: 239px !important;
    margin-left: 1.5em;
    margin-right: 0em;
    margin-bottom: 1em;
  }

  .team .MuiGridListTileBar-title {
    font-size: 15px;
  }

  .team .MuiGridListTileBar-subtitle {
    font-size: 14px;
  }

  .ScrollUpButton__Container {
    right: 5% !important;
    bottom: 6.5% !important;
  }
  .form .icon {
    transform: translateY(-28px) !important;
  }

  .pop .icon {
    transform: translateY(-30.5px) !important;
  }

  .form .popInput .input {
    padding-left: 3em;
    width: 73%;
  }
}
@media (max-width: 759px) {
  .content {
    width: auto !important;
    overflow-x: hidden !important;
    padding-left: 1em;
    padding-right: 1em;
  }

  .MuiTypography-h1 {
    font-size: 30px !important;
  }

  .MuiTypography-h2 {
    font-size: 17px !important;
  }

  .MuiTypography-h3 {
    font-size: 1.29rem !important;
  }

  .MuiTypography-h4 {
    font-size: 1.7rem !important;
  }

  .MuiTypography-h5 {
    font-size: 1.1rem !important;
  }

  .MuiTypography-h6 {
    font-size: 0.9rem !important;
  }

  .popup {
    float: none;
    margin-bottom: 2em;
  }

  .cover .coverContainer img {
    height: 15em;
    top: 0px;
  }

  .provider .cover .title {
    margin-top: -0.5em !important;
    height: 2.5em;
  }

  .functionality .title {
    margin-bottom: 2.5em;
  }
  .home .cover .title {
    margin-top: -0.5em;
    height: 4.5em;
  }

  .home .cover .slogun {
    margin-bottom: 6em;
  }

  .cover .buttons .store {
    width: 6em;
  }

  .provider .cover .slogun {
    margin-bottom: 5.5em;
  }

  .value {
    margin-top: 2em !important;
    margin-bottom: 2em;
  }

  .goal .pizza {
    margin-left: 0em;
    width: 50% !important;
    height: 50% !important;
  }

  .goal .word {
    margin-top: 10%;
  }

  .goal .paragraph {
    width: 100% !important;
  }

  .logoSmall {
    width: 1.5em;
    height: 2.5em;
    margin-top: -0.5em;
  }

  .menuSideBar .icon {
    font-size: 38px !important;
    margin-top: 0em;
  }

  .headroom-wrapper {
    height: 50px !important;
  }

  .headroom--pinned {
    height: 5em !important;
  }

  .notification {
    width: 11.5em;
    padding: 1em;
  }

  .footer .buttons {
    text-align: center;
    margin-top: 1em;
  }

  .footer .icons {
    text-align: center;
  }

  .menu .MuiListItem-root p {
    font-size: 36px !important;
  }

  .menu .MuiTypography-h6 {
    font-size: 36px !important;
  }

  .menuSideBarHeader {
    margin-left: 2em !important;
    margin-right: 2m !important;
  }

  .menu .flag {
    width: 4em;
    height: 4em;
    transform: translateY(4.5px);
  }

  .team .tile {
    width: 45% !important;
    height: 45vw !important;
    margin-bottom: 2em;
    margin-right: 0em;
    margin-left: 1em;
  }

  .team .worker {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .team .MuiGridListTileBar-title {
    font-size: 12px !important;
  }

  .team .MuiGridListTileBar-subtitle {
    font-size: 11.5px !important;
  }

  .team .MuiGridListTileBar-root {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  /* .team .MuiGridListTileBar-title {
    font-size: 10px;
  }

  .team .MuiGridListTileBar-subtitle {
    font-size: 13px;
  } */

  .team .content {
    margin-left: 0em;
  }

  .player {
    width: 100% !important;
    height: 23em !important;
    margin-bottom: 4em !important;
  }

  .react-player__preview {
    height: 100% !important;
  }

  .teamPage .awardTitle {
    margin-bottom: -4em;
  }

  .awards .cl {
    width: 80%;
    margin-bottom: 0em;
  }

  .awards .bs {
    width: 50%;
    margin-bottom: 1em;
  }

  .awards .mi {
    width: 80%;
    margin-bottom: 1em;
  }

  .awards .ggg {
    width: 75%;
    margin-bottom: 1em;
  }

  .teamPage .awardTitle {
    margin-bottom: -2.5em !important;
  }

  .awards .wsa {
    width: 75%;
    margin-bottom: 1em;
  }

  .awards .tournament {
    width: 100%;
    margin-bottom: 1em;
  }

  .awards .logo {
    margin-top: 2em;
  }

  .form .formContainer {
    display: block;
  }

  .form .content {
    padding-right: 0px !important;
    overflow-y: hidden;
  }

  .form .main {
    width: 93% !important;
  }

  .form .input {
    width: 93.5% !important;
    box-shadow: 0 4px 2px -2px #e2dddd;
  }

  .form .message {
    box-shadow: 0 4px 2px -2px #e2dddd;
  }

  .form .field {
    margin-top: 1em;
    padding-bottom: 0.5em;
  }

  .form .popInput .input {
    padding-left: 3em;
    width: 72% !important;
  }
}

@media (max-width: 414px) {
  .content {
    width: auto !important;
    overflow-x: hidden !important;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .MuiTypography-h1 {
    font-size: 30px !important;
  }

  .MuiTypography-h2 {
    font-size: 17px !important;
  }

  .MuiTypography-h3 {
    font-size: 1.29rem !important;
  }

  .MuiTypography-h4 {
    font-size: 1.7rem !important;
  }

  .MuiTypography-h5 {
    font-size: 1.17rem !important;
  }

  .MuiTypography-h6 {
    font-size: 0.9rem !important;
  }

  .popup {
    float: none;
    margin-bottom: 2em;
  }

  .popup button {
    font-size: 12px !important;
  }

  .cover .coverContainer img {
    height: 15em;
    top: 0px;
  }

  .provider .cover .title {
    margin-top: -0.5em !important;
    height: 2.5em;
  }

  .functionality .title {
    margin-bottom: 2.5em;
  }
  .home .cover .title {
    margin-top: -0.5em;
    height: 4.5em;
  }

  .home .cover .slogun {
    margin-bottom: 6em;
  }

  .cover .buttons .store {
    width: 6em;
  }

  .provider .cover .slogun {
    margin-bottom: 5.5em;
  }

  .value {
    margin-top: 2em !important;
    margin-bottom: 2em;
  }

  .goal .pizza {
    margin-left: 0em;
    width: 100% !important;
    height: 100% !important;
  }

  .goal .word {
    margin-top: 1em;
  }

  .logoSmall {
    width: 1.5em;
    height: 2.5em;
    margin-top: -0.5em;
  }

  .menuSideBar .icon {
    font-size: 38px !important;
    margin-top: 0em;
  }

  .headroom-wrapper {
    height: 50px !important;
  }

  .headroom--pinned {
    height: 5em !important;
  }

  .notification {
    width: 11.5m;
    padding: 1em;
  }

  .footer .buttons {
    text-align: center;
    margin-top: 1em;
  }

  .footer .icons {
    text-align: center;
  }

  .menu .MuiListItem-root p {
    font-size: 24px !important;
  }

  .menu .MuiTypography-h6 {
    font-size: 24px !important;
  }

  .menuSideBarHeader {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
  }

  .menu .flag {
    width: 2.5em;
    height: 2.5em;
    transform: translateY(4.5px);
  }

  .goal .content {
    display: block !important;
  }

  .team .tile {
    width: 45% !important;
    height: 202px !important;
    margin-bottom: 1em;
    margin-right: 0em;
    margin-left: 0.7em;
  }

  .team .worker {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .team .MuiGridListTileBar-title {
    font-size: 10.9px !important;
  }

  .team .MuiGridListTileBar-subtitle {
    font-size: 11px !important;
  }

  .team .MuiGridListTileBar-root {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .team .MuiGridListTileBar-title {
    font-size: 17px;
  }

  .team .MuiGridListTileBar-subtitle {
    font-size: 13px;
  }

  .team .content {
    margin-left: 0em;
  }

  .goal .paragraph {
    width: 100% !important;
  }

  .player {
    width: 100% !important;
    height: 12em !important;
    margin-bottom: 4em !important;
  }

  .react-player__preview {
    height: 100% !important;
  }

  .teamPage .awardTitle {
    margin-bottom: -4em;
  }

  .awards .cl {
    width: 100%;
    margin-bottom: 0em;
    margin-top: 0.5em !important;
  }

  .awards .bs {
    width: 50%;
    margin-bottom: 1em;
  }

  .awards .mi {
    width: 100%;
    margin-bottom: 1em;
  }

  .awards .wsa {
    width: 100%;
    margin-bottom: 1em;
    margin-top: 0.5em !important;
  }

  .awards .dc {
    margin-top: 1em;
  }

  .awards .tournament {
    width: 100%;
    margin-bottom: 1em;
  }

  .awards .logo {
    margin-top: 2em;
  }

  .ScrollUpButton__Container {
    bottom: 7% !important;
  }

  .form .main {
    width: 92.5% !important;
  }

  .form .input {
    width: 93% !important;
  }
}
