.functionality .title{
    margin-bottom: 1em;
}

.functionality .header {
    text-align:center;
}

.functionality .main {
    margin-top: 1.5em;
}

.functionality .slogun {
    margin-top: 2em;
    margin-bottom: 4em;
}

.functionality .stepImage {
    object-fit: contain;
    box-shadow: 0 2px 15px 0 #efeff0;
}