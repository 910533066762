.popup {
    float: right;
}

.popup .button {
    font-size: 16px;
    border-radius: 30px;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    border: none;
    background-color: #e91e63 !important;
    color: white;
    font-weight: 700 !important;
    text-transform: none;
    transform: translateY(5px);
}

.popup .heart {
    font-size: 20px;
    margin-left: 0.5em;
    color: white;
    margin-right: 0.25em;
}

.notification {
    padding: 1em;
    padding-left: 2em;
    padding-right: 2em;
    width: 14em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    border-radius: 10px;
    box-shadow: 0 2px 15px 0 #efeff0;
}

.notification .close {
    margin-left: 100%;
    font-size: 20px;
    cursor: pointer;
}
.notification .text {
    text-align: center;
}

.provider .popup {
    margin-top: 2.3em;
}

