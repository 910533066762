.menuSideBar .icon {
    font-size: 40px !important;
    margin-top: 0.2em;
  }
  
  .menuSideBarDrawer .MuiDrawer-paper {
    width: 100% !important;
    height: 100% !important;
    background-color: #fdfff5;
  }
  
  .menuSideBarDrawer .menuSideBarHeader {
    padding-top: 0em;
    margin: auto;
    margin-left: 10%;
    margin-right: 10%;
  }
  
  .menuSideBarDrawer .list {
    margin-top: 0.5em;
  }

  .menuSideBarDrawer .flag {
    width: 6em;
    height: 6em;
  }

  .menuSideBarDrawer #menu-list-grow {
    padding: 0px !important;
    outline: none;
  }

  .menuSideBarDrawer .menuLogo {
    margin-right: auto;
    width: 2em;
    height: 3em;
    padding: 1em;
  }

  .menuSideBarDrawer .language {
    font-size: 60px;
    margin-left: 0.5em;
  }

  .menuSideBarDrawer .tongue {
    transform: translateY(-30px);
  }
  .menuSideBarDrawer .button{
    padding: 0px;
  }
  .menuSideBarDrawer .menuTitle {
    display: inline-flex;
  }
  
  .menuSideBarDrawer .rightArrow {
    font-size: 40px;
    float: right;
  }
  
  .menuSideBarDrawer .MuiList-root {
    padding: 0 0 0 1em !important;
  }
  
  .menuSideBarDrawer .MuiListItem-root {
    padding: 0 !important;
  }
  
  .menuSideBarDrawer .MuiListItemText-root {
    margin: 0 !important;
  }
  
  .menuSideBarDrawer .customDivider {
    padding: 0.75em;
  }
  
  .menuSideBarDrawer .option {
    font-size: 60px!important;
    height: 1.2em;
    margin-top: 0.3em;
  }

  .menuSideBarDrawer .activeHome {
    border-bottom: 3px solid #2ba577 !important;
}

.menuSideBarDrawer .activeTeam {
    border-bottom: 3px solid #2ba577 !important;
}

.menuSideBarDrawer .activeBusiness {
    border-bottom: 3px solid #ef7d50 !important;
}
  