.goal {
    margin-bottom: 2em;
}

.goal .paragraph {
    width: 40em;
    font-size: 18px;
}

.goal .word {
    margin-top: 7em;
    margin-left: 2em;
}

.goal .pizza {
    width: 24em;
    height: 24em;
    margin-left: 5em;
}

.goal .line {
    margin-top: 0.1em;
    margin-bottom: 0.5em;
}

.goal .MuiCircularProgress-root {
    transform: rotate(45deg) !important;
}

.goal .face {
    transform: translateY(7px);
}

.goal .pie {
    color: #607d8b;
    margin-left: 2em;
    margin-right: 2em;
}

.goal .MuiTypography-h4 {
    font-size: 35px !important;
}