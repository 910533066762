.cover {
    margin-top: 5em;
}

.cover .coverContainer img {
    width: 100%;
    position: absolute;
    z-index: -1;
    top:10px;
    left: 0px;
    height: 30em;
    object-fit: cover;
}

.cover .title {
    text-align: center;
    height: 13em;
}

.provider .cover .title {
    text-align: center;
    height: 7.7em;
}

.cover .buttons {
    text-align: center;
    margin-top: 1em;
}

.cover .buttons .store {
    width: 11em;
    margin-right: 1em;
    filter: brightness(150%);
}

.cover .slogun {
    margin-bottom: 10em;
    margin-top: 1em;
    text-align: center;
    margin-right: 1em;
}

.cover .slogun h6 {
    color: white !important;
}

.cover .header {
    color: white;
}
