.pp_body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
} */

.pp_body h1 {
    text-align: center;
    margin-bottom: 30px;
}

.pp_body h2 {
    margin-top: 30px;
    margin-bottom: 20px;
}

.pp_body p {
    margin-bottom: 10px;
}
