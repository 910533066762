@font-face {
    font-family: 'SFCompactDisplay-Black';
    src: local('SFCompactDisplay-Black'), url(../font/SFCompactDisplay-Black.ttf) format('truetype');
}
@font-face {
    font-family: 'SFCompactDisplay-Bold';
    src: local('SFCompactDisplay-Bold'), url(../font/SFCompactDisplay-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'SFCompactDisplay-Heavy';
    src: local('SFCompactDisplay-Heavy'), url(../font/SFCompactDisplay-Heavy.ttf) format('truetype');
}
@font-face {
    font-family: 'SFCompactDisplay-Light';
    src: local('SFCompactDisplay-Light'), url(../font/SFCompactDisplay-Light.ttf) format('truetype');
}
@font-face {
    font-family: 'SFCompactDisplay-Medium';
    src: local('SFCompactDisplay-Medium'), url(../font/SFCompactDisplay-Medium.ttf) format('truetype');
}
@font-face {
    font-family: 'SFCompactDisplay-Regular';
    src: local('SFCompactDisplay-Regular'), url(../font/SFCompactDisplay-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'SFCompactDisplay-SemiBold';
    src: local('SFCompactDisplay-SemiBold'), url(../font/SFCompactDisplay-SemiBold.ttf) format('truetype');
}
@font-face {
    font-family: 'SFCompactDisplay-Thin';
    src: local('SFCompactDisplay-Thin'), url(../font/SFCompactDisplay-Thin.ttf) format('truetype');
}
@font-face {
    font-family: 'SFCompactDisplay-UltraLight';
    src: local('SFCompactDisplay-UltraLight'), url(../font/SFCompactDisplay-UltraLight.ttf) format('truetype');
}

.MuiTypography-900 {
    font-family: 'SFCompactDisplay-Black' !important;
}

.MuiTypography-700 {
    font-family: 'SFCompactDisplay-Heavy' !important;
}

.MuiTypography-500 {
    font-family: 'SFCompactDisplay-SemiBold' !important;
    color: #424242 !important;
}

.MuiTypography-300 {
    font-family: 'SFCompactDisplay-Medium' !important;
}

.notification {
    font-family: 'SFCompactDisplay-SemiBold' !important;
}

.label {
    font-family: 'SFCompactDisplay-Heavy' !important;
}

.button {
    font-family: 'SFCompactDisplay-Bold' !important;
    outline: none;
    cursor: pointer;
    text-decoration: none;
}

.form .message {
    font-family: 'SFCompactDisplay-Regular';
    color: #424242 !important;
}

.form .input {
    font-family: 'SFCompactDisplay-Regular' !important;
    color: #424242 !important;
}

.MuiAlert-message {
    font-family: 'SFCompactDisplay-SemiBold' !important;
    color: white !important;
}