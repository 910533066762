.CookieConsent {
    font-size: 20px;
}

#rcc-confirm-button {
    color: #2ba577 !important;
    font-size: 18px !important;
    background-color: white !important;
    border-radius: 15px !important;
    outline: none !important;
    padding: 0.5em 1.5em 0.5em 1.5em !important;
    font-family: 'SFCompactDisplay-Heavy' !important;
}

.buttonlang {
    color: transparent !important;
}
.MuiButtonBase-root {
    background-color: transparent !important;
}

button:focus, button:active, button {
    background: none !important;
}

.MuiButton-root:focus {
    outline:0 !important;
}

.appbar .MuiAppBar-colorPrimary {
    background-color: transparent !important;
    box-shadow: none !important;
}

.appbar .MuiTypography-root {
    color: black;
}

a {
    text-decoration: none !important;
}
   
.awards a:hover {
    text-decoration: underline !important;
}

.awards a {
    color: #424242;
}

.appfixed .disable a::after {
    content: '';
    position: relative;
    display: block;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0px;
}

.appfixed .orange .disable a::after {
    border-bottom: 3px solid #ef7d50;
}

.appfixed .green .disable a::after {
    border-bottom: 3px solid #2ba577;
}
    
.appfixed a, .appfixed a::after {
    transition: all 0.3s linear;
}
    
.appfixed a:hover::after {
    left: 0;
    width: 100%;
}

body {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  .form .button:focus,.form .button:active, 
  .form .button:active:focus:not(:disabled):not(.disabled),
  .form .button:focus, .form .button:active {
      box-shadow: none!important;
      outline: 0;
      text-decoration: none !important;
      background-color: #2ba577 !important;
    color: white !important;
    border: none !important;
    border-radius: 30px;
  }

.ScrollUpButton__Container {
    right: 49.2% !important;
    bottom: 8% !important;
    border: none !important;
    background-color: #ef7d50 !important;
    fill: white !important;
    border-radius: 100% !important;
    width: 30px !important;
    height: 30px !important;
    padding: 0.25em !important;
    box-shadow: 0 2px 15px 0 #d3d4cd;
    z-index: 99;
}

.ScrollUpButton__Container:focus, .ScrollUpButton__Container:active {
    border: none !important;
    background-color: #ef7d50 !important;
    fill: white !important;
    outline: 0;
    border-radius: 100% !important;
    width: 30px !important;
    height: 30px !important;
    padding: 0.25em !important;
    box-shadow: 0 2px 15px 0 #d3d4cd;
    z-index: 99;
}

.ScrollUpButton__Container svg {
    transform: translateY(0px);
    transform: translateX(2px);
    width: 25px;
    height: 25px;
}

.notification {
    box-shadow: 0 2px 15px 0 #e2dddd;
}

.functionality .stepImage {
    box-shadow: 0 2px 15px 0 #e2dddd;
}

.tongue .MuiPaper-root {
    box-shadow: 0 2px 15px 0 #e2dddd;
}

.form .input {
    box-shadow: 0 2px 15px 0 #e2dddd;
}

.form .message {
    box-shadow: 0 2px 15px 0 #e2dddd;
}

.headroom--pinned {
    background-color: #fefcff !important;
    box-shadow: 0 2px 15px 0  #e2dddd;
}

.menuSideBarDrawer .MuiDrawer-paper {
    background-color: #fefcff ;
  }
  
