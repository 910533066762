.headroom--unfixed {
    position: absolute !important;
    top: 0px !important;
}

.headroom-wrapper {
    height: 72px !important;
}

.headroom--pinned {
    background-color: #fefcff !important;
    height: 5.6em;
    top: 0px !important;
    box-shadow: 0 2px 15px 0  #efeff0 ;
}

.appbar .container {
    display: flex;
}

.appbar .container .main {
    margin-right: 2em;
    margin-top: 1em;
}

.appbar .logo {
    width: 10em;
    padding-right: 24.6em;
    margin-top: 3em;
    transform: translateY(-20px);
}

.appbar .activeHome {
    border-bottom: 3px solid #2ba577 !important;
}

.appbar .activeTeam {
    border-bottom: 3px solid #2ba577 !important;
}

.appbar .activeBusiness {
    border-bottom: 3px solid #ef7d50 !important;
}



