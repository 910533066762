.form .popLabel{
    font-size: 16px !important;
    text-align: left !important;
}

.form .popButton {
    font-size: 16px;
    margin-top: -1em;
    width: 10em;
}

.form .popInput .input {
    height: 3em !important;
    width: 73%;
    font-size: 16px;
    padding-left: 2.5em;
    padding-right: 1em;
    box-shadow: none;
    border: 2px solid #2ba577 !important; 
}

.form .checker {
    margin-top: -1em;
}

.form .checker .MuiTypography-root {
    font-size: 16px;  
    transform: translateY(1px); 
}

.pop .MuiAlert-message {
    margin-left: 1em;
    margin-right: 1em;
    width: 6.5em;
}

.pop .MuiSnackbar-root {
    bottom: 100%;
}

.pop .field {
    margin-top: 1em !important;
}

.pop .form{
    width: 100%;
}

.pop .icon {
    transform: translateY(-35px) !important;
    padding-left: 0.5em;
    color: #424242;
}