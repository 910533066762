.teamPage .title {
  margin-top: 1em;
}

.teamPage .awardTitle {
  margin-bottom: -1em;
}

.awards .comptitle {
  margin-top: 4em;
  text-align: left !important;
}

.awards .awardTitle {
  margin-bottom: 4em !important;
}
.awards .logo {
  margin-top: 0em;
  text-align: center;
}

.awards .carousel {
  margin-top: 0em;
  height: 100%;
  margin-bottom: 2em;
}

.awards .coverPhoto {
  object-fit: cover !important;
  width: 100%;
  border-radius: 30px;
}

.awards .text {
  text-align: center;
  margin-bottom: 0.25em;
}

.awards .tournament {
  margin-bottom: 2em;
}

.awards .cl {
  width: 50em;
  margin-bottom: 1em;
  margin-top: -0.5em !important;
}

.awards .bs {
  width: 15em;
  margin-bottom: 2em;
}

.awards .mi {
  width: 30em;
  margin-bottom: 2em;
}

.awards .ggg {
  width: 25em;
  margin-bottom: 2em;
}

.awards .wsa {
  width: 25em;
  margin-bottom: 2em;
}

.awards .img {
  margin-top: 2em;
}
